<!-- 公共组件-底部菜单 -->
<template>
    <div class="container">
        <div class="contectBox">
            <div class="phoneBox">
                <span>更多问题，请致电 <span style="color: #C5513A;">400-021-3933</span>。</span>
            </div>

            <div class="line"></div>

            <div class="filingInformationBox">
                <div class="informationBox">
                    <span>Copyright © 2024 保留所有权利。 隐私政策｜使用条款</span>
                    <!-- <span>备案号:沪ICP备2022029731号-3 京公网安备11011302007194号 © 版权所有.</span> -->
                    <a href="https://beian.miit.gov.cn">备案号:沪ICP备2022029731号-3</a>
                </div>
                <div class="logoBox">
                    <img src="@/assets/footerMenuLogo.svg">
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

</script>

<style scoped lang="scss">
@media (max-width: 675px) {
    .container {
        display: flex;
        align-items: center;
        padding: 0 60px;
        height: 300px;
        background: #FFFFFF;

        .contectBox {
            display: flex;
            flex-direction: column;
            width: 100%;

            // 更多问题，请致电 400-021-3933。
            .phoneBox {
                span {
                    font-family: MiSans;
                    font-weight: 500;
                    font-size: 16px;
                    font-variation-settings: "opsz" auto;
                }
            }

            // 横线
            .line {
                margin: 14px 0 14px 0;
                width: 100%;
                height: 1px;
                opacity: 1;
                background: #686868;

            }

            // Copyright © 2024 保留所有权利...
            .filingInformationBox {
                display: flex;
                flex-direction: column;
                // justify-content: space-between;

                .informationBox {
                    display: flex;
                    flex-direction: column;
                    width: 100%;

                    span {
                        opacity: 1;
                        font-family: MiSans;
                        font-size: 12px;
                        font-weight: 500;
                        font-variation-settings: "opsz" auto;
                        font-feature-settings: "kern" on;
                        color: #686868;

                    }
                }

                .logoBox {
                    img {
                        width: 100px;
                        height: 28px;
                        opacity: 1;
                        filter: invert(1);
                    }
                }
            }
        }
    }
}

@media (min-width: 676px) and (max-width:1003px) {
    .container {
        display: flex;
        align-items: center;
        padding: 0 160px;
        height: 300px;
        background: #FFFFFF;

        .contectBox {
            display: flex;
            flex-direction: column;
            width: 100%;

            // 更多问题，请致电 400-021-3933。
            .phoneBox {
                span {
                    font-family: MiSans;
                    font-weight: 500;
                    font-size: 16px;
                    font-variation-settings: "opsz" auto;
                }
            }

            // 横线
            .line {
                margin: 14px 0 14px 0;
                width: 100%;
                height: 1px;
                opacity: 1;
                background: #686868;

            }

            // Copyright © 2024 保留所有权利...
            .filingInformationBox {
                display: flex;
                flex-direction: column;
                // justify-content: space-between;

                .informationBox {
                    display: flex;
                    flex-direction: column;
                    width: 100%;

                    span {
                        opacity: 1;
                        font-family: MiSans;
                        font-size: 12px;
                        font-weight: 500;
                        font-variation-settings: "opsz" auto;
                        font-feature-settings: "kern" on;
                        color: #686868;

                    }
                }

                .logoBox {
                    img {
                        width: 100px;
                        height: 28px;
                        opacity: 1;
                        filter: invert(1);
                    }
                }
            }
        }
    }
}

@media (min-width: 1004px) and (max-width: 1225px) {
    .container {
        display: flex;
        align-items: center;
        padding: 0 160px;
        height: 300px;
        background: #FFFFFF;

        .contectBox {
            display: flex;
            flex-direction: column;
            width: 100%;

            // 更多问题，请致电 400-021-3933。
            .phoneBox {
                span {
                    font-family: MiSans;
                    font-weight: 500;
                    font-size: 18px;
                    font-variation-settings: "opsz" auto;
                }
            }

            // 横线
            .line {
                margin: 14px 0 44px 0;
                width: 100%;
                height: 1px;
                opacity: 1;
                background: #686868;

            }

            // Copyright © 2024 保留所有权利...
            .filingInformationBox {
                display: flex;
                justify-content: space-between;

                .informationBox {
                    display: flex;
                    flex-direction: column;
                    width: 100%;

                    span {
                        opacity: 1;
                        font-family: MiSans;
                        font-size: 18px;
                        font-weight: 500;
                        font-variation-settings: "opsz" auto;
                        font-feature-settings: "kern" on;
                        color: #686868;

                    }
                }

                .logoBox {
                    img {
                        width: 183px;
                        height: 48px;
                        opacity: 1;
                        filter: invert(1);
                    }
                }
            }
        }
    }
}

@media (min-width: 1226px) {
    .container {
        display: flex;
        align-items: center;
        padding: 0 160px;
        height: 300px;
        background: #FFFFFF;

        .contectBox {
            display: flex;
            flex-direction: column;
            width: 100%;

            // 更多问题，请致电 400-021-3933。
            .phoneBox {
                span {
                    font-family: MiSans;
                    font-weight: 500;
                    font-size: 18px;
                    font-variation-settings: "opsz" auto;
                }
            }

            // 横线
            .line {
                margin: 14px 0 44px 0;
                width: 100%;
                height: 1px;
                opacity: 1;
                background: #686868;

            }

            // Copyright © 2024 保留所有权利...
            .filingInformationBox {
                display: flex;
                justify-content: space-between;

                .informationBox {
                    display: flex;
                    flex-direction: column;
                    width: 100%;

                    span {
                        opacity: 1;
                        font-family: MiSans;
                        font-size: 18px;
                        font-weight: 500;
                        font-variation-settings: "opsz" auto;
                        font-feature-settings: "kern" on;
                        color: #686868;

                    }
                }

                .logoBox {
                    img {
                        width: 183px;
                        height: 48px;
                        opacity: 1;
                        filter: invert(1);
                    }
                }
            }
        }
    }
}
</style>