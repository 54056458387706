import { createRouter, createWebHistory } from "vue-router"
import routeData from "./routeData.js";

const router = createRouter({
  history: createWebHistory(),
  routes: [...routeData]
})


export default router
