<template>
  <div id="app">
    <NavigationBar></NavigationBar>
    <router-view></router-view>
    <FooterMenu></FooterMenu>
  </div>
</template>

<script>
import NavigationBar from './components/NavigationBar.vue'
import FooterMenu from './components/FooterMenu.vue';
export default {
  name: 'App',
  components: {
    NavigationBar,
    FooterMenu
  }
}
</script>

<style>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
} */
/* html {
  font-size: 12px;
} */
body {
  background-color: #e6e6e6;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  /* 去除下划线 */
  color: inherit;
  /* 使用父元素的颜色 */
}
</style>
