const routes = [
    {// 启动页
        path: '/welcome',
        component: () =>
            import('../pages/WelcomeA.vue')
    },
    {// 关于瑞摩
        path: '/about',
        component: () =>
            import('../pages/AboutRuimo.vue')
    },
    {// Houzzkit
        path: '/houzzkit',
        component: () =>
            import('../pages/HouzzkitA.vue')
    },
    {// 全宅智能
        path: '/intelligence',
        component: () =>
            import('../pages/IntelligentProducts.vue')
    },
    {// 项目案例
        path: '/case',
        component: () =>
            import('../pages/ProjectCase.vue')
    },
    {// 技术支持
        path: '/support',
        component: () =>
            import('../pages/TechnicalSupport.vue')
    },
    {// 联系我们
        path: '/contact',
        component: () =>
            import('../pages/ContactUs.vue')
    },
]

export default routes
