import { createApp } from 'vue'
import App from './App.vue'

// 路由
import router from './router'

// element-plus
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElIcons from '@element-plus/icons-vue'


// 路由
router.afterEach(() => {
    window.scrollTo(0,0)
})


const app = createApp(App)
app.use(router)
app.use(ElementPlus)
for (const [key, component] of Object.entries(ElIcons)) {
    app.component(key, component)
}
app.mount('#app')
