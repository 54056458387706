<!-- 公共组件-头部导航 -->
<template>
  <div class="container">
    <div class="logoBox">
      <img class="logo" src="@/assets/logo.svg" @click="clickLogo">
    </div>
    <div class="navBox">
      <div class="navItem" v-for="(item, index) in navList" :key="index" @click="clickNavBar(index)"
        :style="clickedItemIndex == index ? 'color: #000; font-weight: 600;' : 'color: #686868'">
        <span>{{ item.text }}</span>
      </div>
      <!-- <div class="contactUs" @click="contactUs">
        <span>联系我们</span>
      </div> -->
    </div>
    <!-- <div class="menuBox">
      <img src="@/assets/menuIcon.svg" alt="">
    </div> -->
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRouter } from "vue-router"
const router = useRouter()




const clickLogo = () => {
  router.push('/welcome')

  clickedItemIndex.value = 999
}




// navbar
let navList = ref([
  {
    id: 0,
    text: '关于瑞摩'
  },
  {
    id: 1,
    text: 'HOUZZkit'
  },
  {
    id: 2,
    text: '全宅智能'
  },
  {
    id: 3,
    text: '项目案例'
  },
  {
    id: 4,
    text: '技术支持'
  },
])

/**
 * @param index 点击的nav那一项的索引
 */

let clickedItemIndex = ref(0)

const clickNavBar = (index) => {
  clickedItemIndex.value = index

  if (index === 0) {
    router.push('/about')
  } else if (index === 1) {
    router.push('/houzzkit')
  } else if (index === 2) {
    router.push('/intelligence')
  } else if (index === 3) {
    router.push('/case')
  } else if (index === 4) {
    router.push('/support')
  }
}




// const contactUs = () => {
//   router.push('/contact')

//   clickedItemIndex.value = 999
// }



onMounted(() => {
  router.beforeEach((to, from, next) => {
    if (to.path == '/welcome' || to.path == '/about' || to.path == '/houzzkit' || to.path == '/intelligence' || to.path == '/case' || to.path == '/support') {
      next()

      if (to.path == '/welcome') {
        clickedItemIndex.value = 999
      } else if (to.path == '/about') {
        clickedItemIndex.value = 0
      } else if (to.path == '/houzzkit') {
        clickedItemIndex.value = 1
      } else if (to.path == '/intelligence') {
        clickedItemIndex.value = 2
      } else if (to.path == '/case') {
        clickedItemIndex.value = 3
      } else if (to.path == '/support') {
        clickedItemIndex.value = 4
      }
      return
    } else {
      router.push('/welcome')
    }
  })
})
</script>

<style scoped lang="scss">
@media (max-width: 675px) {
  .container {
    padding: 0 12px 0 12px;
    display: flex;
    justify-content: space-between;
    height: 70px;
    background-color: #fff;
  }

  .logoBox .logo {
    display: none;
  }

  .navBox .navItem {
    margin-right: 18px;

    span {
      font-size: 10px;
    }
  }

  .contactUs {
    width: 74px;
    height: 30px;
    border-radius: 20px;
    opacity: 1;
    background: #C5513A;
    text-align: center;
    cursor: pointer;

    span {
      width: 64px;
      height: 21px;
      opacity: 1;
      font-family: MiSans;
      font-size: 10px;
      font-weight: 600;
      line-height: 30px;
      letter-spacing: 0em;
      color: #FFFFFF;
    }
  }

  // .menuBox {
  //   display: flex;
  //   align-items: center;

  //   img {
  //     width: 24px;
  //   }
  // }
}

@media (min-width: 676px) and (max-width: 1003px) {
  .container {
    padding: 0 84px 0 72px;
    display: flex;
    justify-content: space-between;
    height: 70px;
    background-color: #fff;
  }

  .logoBox .logo {
    width: 100px;
    height: 20px;
    cursor: pointer;
  }

  .navBox .navItem {
    margin-right: 18px;

    span {
      font-size: 12px;
    }
  }

  .contactUs {
    width: 74px;
    height: 30px;
    border-radius: 20px;
    opacity: 1;
    background: #C5513A;
    text-align: center;
    cursor: pointer;

    span {
      width: 64px;
      height: 21px;
      opacity: 1;
      font-family: MiSans;
      font-size: 10px;
      font-weight: 600;
      line-height: 30px;
      letter-spacing: 0em;
      color: #FFFFFF;
    }
  }

  // .menuBox {
  //   display: none;
  // }
}

@media (min-width: 1004px) and (max-width: 1225px) {
  .container {
    padding: 0 84px 0 72px;
    display: flex;
    justify-content: space-between;
    height: 70px;
    background-color: #fff;
  }

  .logoBox .logo {
    width: 160px;
    height: 30px;
    cursor: pointer;
  }

  .navBox .navItem {
    margin-right: 28px;

    span {
      font-size: 18px;
    }
  }

  .contactUs {
    width: 130px;
    height: 40px;
    border-radius: 20px;
    opacity: 1;
    background: #C5513A;
    text-align: center;
    cursor: pointer;

    span {
      width: 64px;
      height: 21px;
      opacity: 1;
      font-family: MiSans;
      font-size: 16px;
      font-weight: 600;
      line-height: 40px;
      letter-spacing: 0em;
      color: #FFFFFF;
    }
  }

  // .menuBox {
  //   display: none;
  // }
}

@media (min-width: 1226px) {
  .container {
    padding: 0 84px 0 72px;
    display: flex;
    justify-content: space-between;
    height: 70px;
    background-color: #fff;
  }

  .logoBox .logo {
    width: 205px;
    height: 42px;
    cursor: pointer;
  }

  .navBox .navItem {
    margin-right: 60px;

    span {
      font-size: 21px;
    }
  }

  .contactUs {
    width: 130px;
    height: 40px;
    border-radius: 20px;
    opacity: 1;
    background: #C5513A;
    text-align: center;
    cursor: pointer;

    span {
      width: 64px;
      height: 21px;
      opacity: 1;
      font-family: MiSans;
      font-size: 16px;
      font-weight: 600;
      line-height: 40px;
      letter-spacing: 0em;
      color: #FFFFFF;
    }
  }

  // .menuBox {
  //   display: none;
  // }
}

.container {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 111;

  // logo
  .logoBox {
    display: flex;
    justify-content: center;
    align-items: center;

    .logo {
      // width: 205px;
      // height: 42px;
    }
  }

  // 导航
  .navBox {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    // 导航内容
    .navItem {
      // margin-right: 60px;
      cursor: pointer;

      span {
        opacity: 1;
        font-family: MiSans;
        // font-size: 21px;
        font-variation-settings: "opsz" auto;
        font-feature-settings: "kern" on;
      }
    }

    // 关于我们按钮
    // .contactUs {
    //   width: 130px;
    //   height: 40px;
    //   border-radius: 20px;
    //   opacity: 1;
    //   background: #C5513A;
    //   text-align: center;
    //   cursor: pointer;

    //   span {
    //     width: 64px;
    //     height: 21px;
    //     opacity: 1;
    //     font-family: MiSans;
    //     font-size: 16px;
    //     font-weight: 600;
    //     line-height: 40px;
    //     letter-spacing: 0em;
    //     color: #FFFFFF;
    //   }
    // }
  }
}
</style>
